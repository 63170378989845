const KNOWN_ANALYTICS_COOKIES = {
    starts_with: ['_gat', '_sp_', '_hj', '_fbp', '_ga_', '_sp_id', '_sp_ses'],
    equals: ['_ga', '_gid', 'hjViewportId', 'ln_or'],
};
export const COOKIES_NAME_START_WITH_REGEX = new RegExp(
    `(${KNOWN_ANALYTICS_COOKIES['starts_with'].join('|')}).*$`
);
export const COOKIES_NAME_EQUALS_REGEX = new RegExp(
    `^(${KNOWN_ANALYTICS_COOKIES['equals'].join('|')})$`
);

export const EXCLUDED_PAGES = ['/getting-started'];
