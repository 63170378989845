// @ts-strict-ignore
import { isDebugMode } from '../debug/isDebug';

const SITE_ID = 2226940;

type WindowWithHJ = typeof window & { hj: any, _hjSettings: Record<string, any>}
export function initHotjar() {
    if (!isDebugMode()) {
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){ (h.hj.q=h.hj.q||[]).push(arguments); };
            h._hjSettings={ hjid:SITE_ID, hjsv:6 };
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window as WindowWithHJ,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }
}
