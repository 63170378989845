// @ts-strict-ignore
import $ from 'jquery';
import { browser } from './detectBrowser';

$(function() {
    function showSignInIfBrowserIsUnsupported() {
        if (browser.isUnsupported()) {
            $('#sign-in-btn').removeClass('hidden');
        }
    }

    showSignInIfBrowserIsUnsupported();
});
