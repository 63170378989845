const mtUserInfoSelector = '#ga-user';

export function deleteUserInfoNode() {
    const node = document.querySelector(mtUserInfoSelector);
    if (node) {
        node.remove();
    }
}

export function removeLoginHint(location: string) {
    const loginHintRegex = /([?&])(login_hint=[^&]*&?)/;
    const trailingQuestionMarkOrAmpersand = /[?&]$/;

    return location
        .replace(loginHintRegex, '$1')
        .replace(trailingQuestionMarkOrAmpersand, '');
}
