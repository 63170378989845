// @ts-strict-ignore
import {
    COOKIES_NAME_EQUALS_REGEX,
    COOKIES_NAME_START_WITH_REGEX
} from './constants';
import Cookies from 'js-cookie';
import { deleteUserInfoNode } from './utilsGA';
import { initGTM } from './initGTM';
import { initHotjar } from './initHotjar';

let needsReload = false;

const reloadPage = () => window.location.reload();

const getThirdPartyCookieDomain = () => `.${window.location.hostname}`;

const removeAnalyticsCookies = () => {
    const cookies = Cookies.get();

    Object.keys(cookies).forEach(cookie => {
        if (COOKIES_NAME_EQUALS_REGEX.test(cookie) || COOKIES_NAME_START_WITH_REGEX.test(cookie)) {
            Cookies.remove(cookie);
            Cookies.remove(cookie, { domain: getThirdPartyCookieDomain() });
        }
    });
};

const buildInitAnalytics = () => {
    deleteUserInfoNode();

    return () => {
        initGTM();
        initHotjar();
    };
};

export const initAnalytics = buildInitAnalytics();

window.addEventListener('CookiebotOnAccept', () => {
    if (window.Cookiebot.consent?.statistics) {
        initAnalytics();
    } else {
        removeAnalyticsCookies();
        if (needsReload) {
            needsReload = false;
            reloadPage();
        }
    }
}, false);

window.addEventListener('CookiebotOnDecline', () => {
    removeAnalyticsCookies();
    if (needsReload) {
        needsReload = false;
        reloadPage();
    }
}, false);

window.addEventListener('CookiebotOnDialogDisplay', () => {
    needsReload = true;
}, false);