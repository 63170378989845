// @ts-strict-ignore
import tippy from 'tippy.js';

const tooltipArrowAttribute = 'mt-tooltip-arrow';
const tooltipContentAttribute = 'mt-tooltip-content';
const tooltipThemeAttribute = 'mt-tooltip-theme';

export const initTooltips = () => {
    const tooltipReceivers = document.querySelectorAll(`[${tooltipContentAttribute}]`);

    tooltipReceivers.forEach(tooltipReceiver => {
        tippy(tooltipReceiver, {
            allowHTML: true,
            aria: {
                expanded: true,
            },
            arrow: tooltipReceiver.hasAttribute(`${tooltipArrowAttribute}`),
            content: tooltipReceiver.getAttribute(`${tooltipContentAttribute}`),
            interactive: true,
            theme: `mt ${tooltipReceiver.getAttribute(`${tooltipThemeAttribute}`)}`,
        });
    });
};

initTooltips();
