// @ts-strict-ignore
import { EXTENSION_WAS_INSTALLED_EVENT } from './constants';
import { tryJsonParse } from '../../utils/tryJsonParse';
import { InstallButtonConfig } from './InstallButtonConfig';

const SELECTORS = {
    detectExtensionData: 'detect-extension-data',
    installButton: '.install',
    installButtonConfiguration: (id) => `${id}-button-configuration`,
    signInButton: 'sign-in-btn',
    popUpInstallButton: 'add-chrome'
};

interface DetectDataExtensionConfig {
    installExtUrl: string;
    extInstalledUrl: string;
}

(() => {
    const detectDataElement = document.getElementById(SELECTORS.detectExtensionData);

    if (!detectDataElement) return;

    const detectData = tryJsonParse<DetectDataExtensionConfig, null>(detectDataElement?.innerHTML);

    const changeInstallButtonsStatusesToInstalled = (): void => {
        const installButtons = document.querySelectorAll(SELECTORS.installButton) as NodeListOf<HTMLAnchorElement>;

        installButtons.forEach(installButton => {
            const { id } = installButton;
            const configElement = document.getElementById(SELECTORS.installButtonConfiguration(id));
            const config = tryJsonParse<InstallButtonConfig, null>(
                configElement?.innerHTML, 
                { message: 'Could not parse button config', value: null }
            );

            if (!config) return;

            if (config.disabledOnInstalled) {
                installButton.classList.add('disabled');
                if ('onInstalledText' in config) {
                    installButton.getElementsByClassName('text')[0].innerHTML = config.onInstalledText;
                }
            }

            if(!config.isNew) {
                installButton.href = config.onInstalledLink;
                installButton.innerHTML = config.installedLabel;
            }
        });
    };

    const hidePopupInstallButton = (): void => {
        const popupInstallButton = document.getElementById(SELECTORS.popUpInstallButton);

        if (!popupInstallButton) {
            return;
        }

        popupInstallButton.classList.add('hidden');
    };

    const showSignInButton = (): void => {
        const signInButton = document.getElementById(SELECTORS.signInButton);

        if (!signInButton) {
            return;
        }

        signInButton.classList.remove('hidden');
    };

    document.querySelector('body').addEventListener(EXTENSION_WAS_INSTALLED_EVENT, () => {
        if (location.pathname.includes(detectData.installExtUrl) && location.search.includes('addon=1')) {
            location.href = detectData.extInstalledUrl;
            return;
        }

        changeInstallButtonsStatusesToInstalled();
        hidePopupInstallButton();
        showSignInButton();
    });
})();

