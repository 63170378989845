// @ts-strict-ignore
function initCloseButton() {
    const mobileCloseButton = document.querySelector('#nav-icon');

    if (!mobileCloseButton) {
        return;
    }

    mobileCloseButton.addEventListener('click', e => e.currentTarget.classList.toggle('active'));
}

initCloseButton();
